nav.navbar.navbar-default {
  margin-bottom: 0;
}

.navibar {
  
}

.navitext{
  /* background: seashell; */
  background: radial-gradient(circle, #1e8eb0,  #145b70, #0a2d38 );
  font-size: larger;
}