.portfolioCard {
    margin: 15px;
}

.tabletop {
    padding-left: 20%;
}

.aboutBack {
    background: radial-gradient(circle, #b0c9af, #afc9c9, #afb8c9);
}